import { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxHeart, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { motion } from 'framer-motion';
import cx from 'classnames';

import { formatCurrency } from 'lib/formatters';
import { useCampaignPage } from 'context/CampaignPage';
import RichText from 'components/common/RichText';
import CampaignPageSectionHeader from './CampaignPageSectionHeader';

const GET_SPONSOR_PACKAGES = gql`
  query GetCampaignPageSponsorPackages($id: String!) {
    findCampaigns(id: $id) {
      id
      registrationTypes(order: "reverse:amount", where: { type: "sponsor", isEnabled: true }) {
        id
        name
        amount
        description
        sponsorBenefits

        qtyUsed
        qtyTotal
        qtyRemaining
        includedTicketQuantity

        includedTicketOption {
          id
          qtyRemaining
        }
      }
    }
  }
`;

const GET_WIDGET = gql`
  query GetCampaignPageSponsorPackagesWidget($id: String!) {
    findCampaignWidgets(id: $id) {
      id
      title
      config
    }
  }
`;

const SponsorPackageExpandableBlock = ({
  name,
  amount,
  description,
  sponsorBenefits,
  qtyUsed,
  qtyTotal,
  qtyRemaining,
  includedTicketQuantity,
  includedTicketOption,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const canExpand = !!description || sponsorBenefits.length !== 0;
  const isSoldOut =
    qtyRemaining === 0 ||
    (includedTicketOption?.qtyRemaining !== null &&
      includedTicketQuantity > includedTicketOption?.qtyRemaining);

  return (
    <div
      role="presentation"
      onClick={canExpand ? () => setIsExpanded(!isExpanded) : () => {}}
      className={cx('border border-gray-400 shadow-lg rounded-2xl p-8 overflow-hidden bg-white', {
        'cursor-pointer': canExpand,
      })}
    >
      <div className="flex items-start sm:items-center justify-between">
        <div className="w-full">
          <div className="overflow-hidden">
            <h2 className="text-xl font-medium lg:w-3/4 line-clamp-2">{name}</h2>
          </div>
          <div className="flex sm:hidden gap-12 mt-4">
            {qtyTotal || isSoldOut ? (
              <div
                className={cx(
                  'flex items-center gap-2 text-sm font-medium py-1 px-2 rounded-full',
                  {
                    'bg-red-100 text-red-500': isSoldOut,
                    'bg-green-100 text-green-600': !isSoldOut,
                  }
                )}
              >
                <FontAwesomeIcon icon={faBoxHeart} />
                <p className="text-nowrap">{isSoldOut ? 'SOLD OUT' : `${qtyUsed}/${qtyTotal}`}</p>
              </div>
            ) : null}
            <p className="sm:hidden text-2xl text-right">{formatCurrency(amount)}</p>
          </div>
        </div>
        <div className="flex items-center gap-8">
          {qtyTotal || isSoldOut ? (
            <div
              className={cx(
                'hidden sm:flex items-center gap-2 text-sm font-medium py-1 px-2 rounded-full',
                {
                  'bg-red-100 text-red-500': isSoldOut,
                  'bg-green-100 text-green-600': !isSoldOut,
                }
              )}
            >
              <FontAwesomeIcon icon={faBoxHeart} />
              <p className="text-nowrap">{isSoldOut ? 'SOLD OUT' : `${qtyUsed}/${qtyTotal}`}</p>
            </div>
          ) : null}
          <div className="flex items-center gap-4">
            <p className="hidden sm:block text-2xl text-right">{formatCurrency(amount)}</p>
            {canExpand ? (
              <button
                as="button"
                type="button"
                className="h-10 w-10 rounded-full shrink-0"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} className="text-gray-600" />
              </button>
            ) : (
              <div className="w-10" />
            )}
          </div>
        </div>
      </div>
      {isExpanded && (
        <motion.div
          key="sponsor-package-description"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.25 }}
          className="flex flex-col sm:flex-row mt-10 gap-8 sm:gap-20 pb-4"
        >
          {description && (
            <RichText className="flex-1 text-gray-600 text-lg" content={description} />
          )}
          {sponsorBenefits.length !== 0 && (
            <div className="flex-1 text-sm">
              <p className="font-medium border-b border-gray-400 w-full py-3">
                This Package Includes:
              </p>
              {sponsorBenefits.map((benefit, i) => (
                <p key={i} className="w-full border-b border-gray-400 py-3">
                  {benefit}
                </p>
              ))}
            </div>
          )}
        </motion.div>
      )}
    </div>
  );
};

const CampaignPageSponsorPackages = ({ id, className }) => {
  const { campaignId } = useCampaignPage();
  const packagesQuery = useQuery(GET_SPONSOR_PACKAGES, { variables: { id: campaignId } });
  const packages = packagesQuery?.data?.findCampaigns?.[0]?.registrationTypes ?? [];
  const { data } = useQuery(GET_WIDGET, { variables: { id } });
  const block = data?.findCampaignWidgets[0];

  if (!block) return null;
  return packages.length !== 0 ? (
    <div
      className={cx('py-16', className)}
      style={{ backgroundColor: block?.config?.backgroundColor }}
    >
      <div className="container max-w-7xl">
        {block.title && (
          <CampaignPageSectionHeader
            title={block.title}
            titleClassName="text-gray-800"
            style={{ color: block?.config?.titleColor }}
          />
        )}
        <div className="mt-10">
          <div className="grid grid-cols-1 gap-y-5">
            {packages.map((pkg) => (
              <SponsorPackageExpandableBlock key={pkg.id} {...pkg} />
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

CampaignPageSponsorPackages.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CampaignPageSponsorPackages.defaultProps = {
  className: '',
};

export default CampaignPageSponsorPackages;
